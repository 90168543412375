import React, { useCallback, useMemo, useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useStaticQuery, graphql, navigate as gatsbyNavigate } from 'gatsby';
import { decodeQueryParams, StringParam } from 'serialize-query-params';
import { useLocation } from '@reach/router';
import { Box } from 'grommet';
import { storyblokEditable } from 'gatsby-source-storyblok';

import { useStoryblokState } from '../../lib/storyblok';
import isBrowser from '../../lib/isBrowser';
import { map, find, flatten, compact, get } from '../../lib/nodash';
import deNodify from '../../lib/deNodify';
import BrowsePaintProducts from '../Paint/BrowsePaintProducts';
import { CommaArrayParam } from '../../lib/customParams';
import {
  toggleGenericFilter,
  extractAllFilters,
  toggleSort,
} from '../../lib/filters/filterRouteHelpers';

import {
  setFilterQuery,
  setFilterTags,
  setSorts,
} from '../../state/color/colorSlice';

const getFallbackBaseUrl = (pathname) => {
  if (pathname.indexOf('-paint') === -1) {
    return pathname;
  } else {
    const slicedUrl = pathname.slice(0, pathname.indexOf('-paint'));
    const frags = slicedUrl.split('/');
    const remainder = frags[frags.length - 1];
    const fallbackBaseUrl = slicedUrl.replace(remainder, '');
    return fallbackBaseUrl;
  }
};

const DynamicBrowsePaintProducts = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchString, setSearchString] = useState(location.search);
  const windowLocation = isBrowser ? window.location : location;

  const navigate = (string) => {
    window.history.replaceState(undefined, undefined, string);
    setSearchString(string);
  };

  const { paint, arrangement, colorWheel, filters } = useStaticQuery(graphql`
    query DynamicPaintBrowserContent {
      paint: allShopifyCollection(
        filter: {
          metafields: {
            elemMatch: {
              key: { eq: "type" }
              namespace: { eq: "collection" }
              value: { eq: "Paint" }
            }
          }
        }
        sort: {
          order: [ASC, ASC]
          fields: [
            products___variants___product___handle
            products___variants___id
          ]
        }
      ) {
        edges {
          node {
            id
            shopifyId
            title
            handle
            metafields {
              type
              key
              value
              description
              namespace
            }
            products {
              id
              shopifyId
              title
              productType
              variants {
                id
                shopifyId
                price
                title
                availableForSale
                inventoryPolicy
              }
            }
          }
        }
      }

      colorWheel: strapiColorWheel {
        Colors {
          color {
            name
            shopify_product_ids
          }
        }
      }

      filters: storyblokEntry(
        full_slug: { eq: "filters/default-paint-filters" }
      ) {
        content
        id
        slug
        uuid
        internalId
      }

      arrangement: storyblokEntry(
        full_slug: { eq: "paint/default-paint-arrangement" }
      ) {
        full_slug
        content
        id
        slug
        uuid
        internalId
      }
    }
  `);

  // Data sources
  const filterStory = useStoryblokState(filters);
  const filterGroups = useMemo(
    () => get('content.filterGroups', filterStory) || [],
    [filters]
  );

  const paintCollections = deNodify(paint);

  const arrangementStory = useStoryblokState(arrangement);
  const paintCollectionArrangement = (
    get('content.paintColors', arrangementStory) || []
  ).map((x) => get('paintColor.item.shopifyId', x));

  // Parse filters from URL...
  const searchParams = useMemo(
    () => queryString.parse(searchString),
    [searchString]
  );

  const decodedQuery = useMemo(
    () =>
      decodeQueryParams(
        {
          filter: CommaArrayParam,
          sort: StringParam,
        },
        searchParams
      ),
    [searchParams, decodeQueryParams, CommaArrayParam, StringParam]
  );
  const colorTagsRules = (props.ruleSet?.rules || []).filter((r) => {
    return (
      r.column === 'TAG' &&
      (r.condition.includes('color:') || r.condition.includes('badge:')) &&
      r.relation === 'EQUALS'
    );
  });

  const activeFilters = useMemo(() => {
    return compact(
      map(
        (x) => {
          return (
            find(
              { slug: `color:${x}` },
              flatten(map('filters', filterGroups))
            ) || find({ slug: x }, flatten(map('filters', filterGroups)))
          );
        },
        [
          ...extractAllFilters(searchParams),
          ...colorTagsRules.map((r) => r?.condition?.replace('color:', '')),
          ...(props.blok?.filterTags ? props.blok.filterTags.split(',') : []),
        ]
      )
    );
  }, [
    decodedQuery.filter,
    filterGroups,
    colorTagsRules,
    props.blok?.filterTags,
  ]);

  const activeSorts = useMemo(
    () => map((x) => ({ id: x, sort: true }), compact([decodedQuery.sort])),
    [decodedQuery.sort]
  );

  // Dispatch filter actions
  useEffect(() => {
    dispatch(setFilterTags(activeFilters));
  }, [activeFilters]);

  useEffect(() => {
    dispatch(setSorts(activeSorts));
  }, [activeSorts]);

  useEffect(() => {
    setSearchString(location.search);
  }, [location]);

  const handleFilter = useCallback(
    (filter) => {
      if (colorTagsRules.map((r) => r.condition).includes(filter.slug)) {
        gatsbyNavigate(`/collections/paint${searchString}`);
      } else if (
        location.pathname.includes(
          `${filter.slug.replace('color:', '')}-paint/`
        )
      ) {
        gatsbyNavigate(
          location.pathname.split(
            `${filter.slug.replace('color:', '')}-paint`
          )[0]
        );
      } else {
        navigate(
          `?${toggleGenericFilter(filter, windowLocation, filterGroups)}`
        );
      }
    },
    [
      toggleGenericFilter,
      windowLocation,
      filterGroups,
      searchString,
      location.pathname,
    ]
  );

  const handleSort = useCallback(
    (sort) => {
      navigate(`?${toggleSort(sort, windowLocation)}`);
    },
    [windowLocation, navigate, toggleSort]
  );

  const handleSearch = useCallback(
    (query) => {
      dispatch(setFilterQuery(query));
    },
    [setFilterQuery, dispatch]
  );
  const handleReset = useCallback(() => {
    navigate(windowLocation.pathname.split('?')[0]);
  }, [navigate, windowLocation]);

  return (
    <Box {...storyblokEditable(props.blok)}>
      <BrowsePaintProducts
        {...{
          ...props.blok,
          baseUrl: props.blok?.baseUrl || getFallbackBaseUrl(location.pathname),
        }}
        handleFilter={handleFilter}
        handleSort={handleSort}
        filterGroups={filterGroups}
        paintCollections={paintCollections}
        paintCollectionArrangement={paintCollectionArrangement}
        colorWheel={colorWheel}
        handleSearch={handleSearch}
        handleReset={handleReset}
        activeFilters={activeFilters}
        activeSorts={activeSorts}
        pageSlug={props.pageSlug}
        pageTitle={props.pageTitle}
        ruleSet={props.ruleSet}
        colorRules={colorTagsRules}
      />
    </Box>
  );
};

export default memo(DynamicBrowsePaintProducts);

DynamicBrowsePaintProducts.propTypes = {
  blok: PropTypes.shape({
    filterTags: PropTypes.string,
    baseUrl: PropTypes.string,
  }),
  ruleSet: PropTypes.object,
  pageTitle: PropTypes.string,
  pageSlug: PropTypes.string,
};
